<template>
  <div class="container">
    <div class="title">状元堂简介</div>
    <div class="content">
      <div class="contentTopIntroduction">
        温州市龙湾区状元教会，于1960年由当地循道公会和耶稣自立会两个教派合并而成。当前教会以状元福音化为使命，以创建学院式教会为目标，坚持“自治、自养、自传”原则，竭力效法基督、光照周围。
      </div>
      <div class="contentTopIntroductionPic">
        <img src="../assets/image/churchIntroduction/first.jpeg" alt="" />
      </div>
      <viewer
        class="relativeIntroductionItem"
        v-for="(item, index) in churchIntroductionMessage"
        :key="index"
      >
        <div style="text-indent: 2em">{{ item.desc }}</div>
        <img :src="item.pic" alt="" />
      </viewer>
      <div class="finallyIntroduction">
        状元堂历届堂委主任分别是：陈醒初、陈宝聪、黄海平、陈国光、江国明，现堂委主任为陈国光长老。教会实施片区牧养，由四位长老牵头负责各自片区内探访祷告与红白喜事。步入教会成立60周年，神恩典的脂油满溢于教会成长的路径，如同眼见；展望未来，教会持定荣神益人核心目标，把持基督教中国化方向，继续完善教会管理机制，进一步打造学习型教会，使教会稳定、健康发展。
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      churchIntroductionMessage: [
        {
          desc: "  据有关资料显示，英国孙光德牧师于1906年来温传道创建状元下市教堂。教会负责人为蔡庆庚长老，买地0.95亩作为堂点地基，其建设资金由国外捐助。教堂位于下市路59号，占地约250平，东至渔业大队和庙宇，南面为民房，西面为厨房与宿舍，北至下市路（路外为瓯江江岸，今建江滨路）。据回忆，该堂内上方高悬一片“真道觉明”的匾额，东西两侧开玻璃窗，中间撑着木质圆柱，柱上一面写着对联并加髹漆，堂内靠凳座位可坐200人左右。当时下市教会信徒有男48人、女34人。下市堂隶属中华基督教循道公会，历任坐堂有孙、胡、葛教士，裘岩南、盛先生、邹松来等，教会负责人除蔡庆庚外，还有邵明一、黄明恩等。",
          pic: require("../assets/image/churchIntroduction/second.jpeg"),
        },
        {
          desc: "",
          pic: require("../assets/image/churchIntroduction/secondAdd.jpeg"),
        },
        {
          desc: "1929年，耶稣教自立会永光堂总会在状元老街设立分会，建有状元老街堂。教堂位于状元老街弯角，有4间房（约80平），其中圣堂3间平房，厨房、宿舍一间两层并供坐堂先生住宿。教堂大门临街朝东，堂内分男女两排坐凳，中间撑着木柱，右边墙壁上有亚当、夏娃在伊甸园吃分别善恶果子的漫画，讲经台两边挂有“禁止喧哗”的牌子，堂内可坐100人左右。历任坐堂先生有庄道兴、潘安民、孙省三、林先生、李兆华、傅其昌等，教会负责人为陈醒初长老。当时自立会分会也称为区会，状元区会下辖七都门前沙教会，负责人王金楷；蒲州教会，负责人冯福桃；社田教会，负责人王步有；丁岙教会，负责人邱岩锡；木家桥教会，负责人应高洪；永强教会，负责人陈汉真。",
        },
        {
          desc: "1951年，状元老街聚会点迁至风雷桥（现六龙桥）。1958年，下市聚会点和老街聚会点因“大跃进”停止聚会。1960年，风雷桥聚会点房产卖给农药厂，下市聚会点房产卖给渔业大队，从此两个不同教派的聚会点走上合一道路，更名为状元教会。1966—1976年，正值十年“文化大革命”，教堂被迫停止聚会，信徒只得在杨宅一带信徒家里举行聚会。1980年，宗教政策逐渐落实，教会恢复了正常聚会。此后，信徒与日俱增，于是，教会决定在状元前潘路40号（现门牌号变更为16号）选址建造状元教堂，共投资13万元。当年落成的教堂是哥特式风格，正立面有一个钟楼。",
          pic: require("../assets/image/churchIntroduction/three.jpeg"),
        },
        {
          pic: require("../assets/image/churchIntroduction/four.jpeg"),
        },
        {
          desc: "1986年，平时到状元教会聚会的石坦村信徒增长至一百多人，为方便石坦村信徒聚会，龙湾区基督教两会与状元教会决定，在石坦村设立聚会点，并派程碎同工主持圣工工作。1989年，石坦聚会点的信徒在山脚边选址建造教堂。1994年，平时到状元教会聚会的御岙村信徒增长至二百多人，为方便御岙村信徒聚会，区两会与状元教会决定，在御岙村设立教堂并派王金润同工主持建堂工作。二年后，御岙教会竣工并投入使用。",
        },
        {
          desc: "状元教会建堂之后，创建了主日学事工。儿童是教会的未来，“教养孩童，使他走当行的道，就是到老他也不偏离”是圣经的教导。本堂全面开展主日学事工，建设主日学教室，搜集主日学资料，训练主日学老师。经过十年的不懈努力，初见成效并推动该事工在温州蓬勃发展。1995-1999年，教会趁势而上，践行“家庭使命就是大使命”理念，举办多场以“全家归主”为主题的布道会，带领不少家庭实现“全家福”。",
          pic: require("../assets/image/churchIntroduction/six.jpeg"),
        },
        {
          desc: "",
          pic: require("../assets/image/churchIntroduction/seven.jpeg"),
        },
        {
          desc: "",
          pic: require("../assets/image/churchIntroduction/eight.jpeg"),
        },
        {
          desc: "迈入21世纪，教会面临新的挑战。旧有家长式的管理模式已经跟不上新时代的步伐，现有堂点亦无法满足信徒的需要，制约了教会的发展。在软件上，教会探索从原来的行动导向转型为目标导向，并以“管好教会”和“学习使命”为目标，推动教会转型进入深水区；在硬件上，教会于2000年夏在旧址重建，于2002年5月18日落成新堂，耗资430万元，从原来建面约400平方米扩建到2000多平方米。新堂外形简洁大方，整体力求塑造出一种韵律感、庄严感和现代感。讲台立面采用哥特式窗门样式，用四个朝天的“心”向里延伸重叠，以表达将众人的心引到天父上帝里面。主堂可同时容纳1000人聚会，其中一层700个座位，夹层300个座位；2020年6月，化疫情为时机，四个月完成主堂装修，为教会日后发展提供了硬件保障。重装后的主堂结构显而易见，各种硬件设施一目了然，敞开于人前。主色调由白色和原木色组成，白色象征洁白、透明，原木色代表自然、客观，正与本堂光明磊落、公开透明、返璞归真的牧会理念相契合。",
          pic: require("../assets/image/churchIntroduction/nine.jpeg"),
        },
        {
          pic: require("../assets/image/churchIntroduction/ten.jpeg"),
        },
        {
          desc: "一个健康的教会，由许多健康元素组成。称职的牧会团队（圣职人员），是健康教会的首要条件。牧会团队是教会的重要组成部分，要在所任职务上具备相应能力，并忠于职守。本堂现有五位在职长老，与四位全职同工组成了牧会团队。",
        },
        {
          pic: require("../assets/image/churchIntroduction/eleven.jpeg"),
        },
        {
          desc: "其次，明确且来自上帝的异象，是办好教会的重要前提。“没有异象（或作“默示”），民就放肆。”（箴29:18）本堂的异象是——办好教会，为实现状元福音化的美好愿望而尽心竭力。数十年来哺育出一个健康教会的雏型，为实现状元福音化奠定了基础。",
        },
        {
          pic: require("../assets/image/churchIntroduction/twolve.jpeg"),
          desc: "第三， 完善的管理体制。从2000年开始，本堂陆续制订了教会章程、信徒代表会议制度 、堂委会换届制度、财务管理制度等，现已编订成《状元堂管理手册》。管理制度的完善，是健康教会的标志之一，也是推动异象落地的重要保障。",
        },
        {
          desc: "最后，是切实有效的牧养体系。本堂每月开展教牧管理系统培训，提升堂委会管理能力；多渠道培养年轻讲道人，确保可持续发展；实施分片牧养，提升和细化牧会工作；着力打造学习型教会，多层次提升教会整体素养。现周间有一千人次参加各类学习，形成了良好的学习氛围。",
        },
      ],
    };
  },
  created() {},
  methods: {},
};
</script>
<style lang="less" scoped>
@media (max-width: 768px) {
  .container {
    width: 90vw;
    overflow-y: hidden;
    margin: 20px auto 0 auto;
    // border: 1px solid #aaa;
    box-shadow: 0 0 3px rgb(0 0 0 / 30%);
    height: auto;
    text-align: center;
    .title {
      margin-top: 20px;
      font-size: 30px;
      line-height: 100px;

      height: 100px;
      color: #777;
      text-shadow: 0 0 1px rgb(0 0 0 / 5%), 0 1px 2px rgb(0 0 0 / 30%);
      margin-bottom: 30px;
      border-bottom: 1px dashed #eee;
    }
    .content {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      //顶部说明
      .contentTopIntroduction {
        width: 90%;
        text-align: left;
        margin-bottom: 10px;
        font-size: 1em;
      }
      // 顶部配图
      .contentTopIntroductionPic {
        width: 90%;
        img {
          width: 100%;
          height: auto;
        }
      }
      // 接下来的说明
      .relativeIntroductionItem {
        width: 90%;
        text-align: left;
        margin: 0;
        font-size: 1em;
        img {
          width: 100%;
          height: auto;
        }
      }
      .finallyIntroduction {
        text-indent: 2em;
        width: 90%;
        text-align: left;
        margin: 10px 0;
        font-size: 1em;
        img {
          margin-top: 20px;
          width: 100%;
          height: auto;
        }
      }
    }
  }
}

@media (min-width: 768px) {
  .container {
    width: 75vw;
    margin: 20px auto 0 auto;
    box-shadow: 0 0 3px rgb(0 0 0 / 30%);
    text-align: center;
    .title {
      font-size: 30px;
      height: 100px;
      line-height: 100px;
      color: #777;
      text-shadow: 0 0 1px rgb(0 0 0 / 5%), 0 1px 2px rgb(0 0 0 / 30%);
      margin-bottom: 30px;
      border-bottom: 1px dashed #eee;
    }
    .content {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      //顶部说明
      .contentTopIntroduction {
        width: 70%;
        text-align: left;
        margin-bottom: 20px;
        font-size: 2em;
      }
      // 顶部配图
      .contentTopIntroductionPic {
        width: 70%;
        img {
          width: 100%;
          height: auto;
        }
      }
      // 接下来的说明
      .relativeIntroductionItem {
        width: 70%;
        text-align: left;
        font-size: 2em;

        img {
          width: 100%;
          height: auto;
        }
      }
      .finallyIntroduction {
        width: 70%;
        text-align: left;
        margin: 20px 0;
        font-size: 2em;
        text-indent: 2em;
        img {
          margin-top: 20px;
          width: 100%;
          height: auto;
        }
      }
    }
  }
}
</style>
