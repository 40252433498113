<template>
  <div class="container">
    <div class="title">状元堂互联网宗教信息服务许可证</div>
    <div class="partTimePic">
      <!-- <div class="partTimeTitle">聚会时间表</div> -->
      <viewer>
        <img
          src="../assets/image/ReligionPermission.jpg"
          alt=""
          style="width: 60%"
        />
      </viewer>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  created() {
    document.body.scrollTo(0, 0);
  },
  methods: {},
};
</script>
<style lang="less" scoped>
.container {
  width: 75vw;
  margin: 20px auto 0 auto;
  box-shadow: 0 0 3px rgb(0 0 0 / 30%);
  text-align: center;
  .partTimePic {
    .partTimeTitle {
      margin-top: 20px;
      font-size: 25px;
      height: 50px;
      color: #777;
      text-shadow: 0 0 1px rgb(0 0 0 / 5%), 0 1px 2px rgb(0 0 0 / 30%);
      margin-bottom: 30px;
      border-bottom: 1px dashed #eee;
    }
  }
  .partPositionPic {
    .partPositionTitle {
      margin-top: 20px;
      font-size: 25px;
      height: 50px;
      color: #777;
      text-shadow: 0 0 1px rgb(0 0 0 / 5%), 0 1px 2px rgb(0 0 0 / 30%);
      margin-bottom: 30px;
      border-bottom: 1px dashed #eee;
    }
  }
  .title {
    font-size: 30px;
    line-height: 100px;
    height: 100px;
    color: #777;
    text-shadow: 0 0 1px rgb(0 0 0 / 5%), 0 1px 2px rgb(0 0 0 / 30%);
    margin-bottom: 30px;
    border-bottom: 1px dashed #eee;
  }
}
</style>
