<template>
  <div class="container">
    <div class="title">联系我们</div>
    <div class="tel">
      <svg class="icon" aria-hidden="true">
        <use xlink:href="#icon-dianhua1"></use></svg
      >电话号码: 13587635033
    </div>
    <div class="position">
      <svg class="icon" aria-hidden="true">
        <use xlink:href="#icon-daohang"></use></svg
      >地点: 温州市龙湾区状元街道前潘路16号
      <a
        href="https://www.amap.com/place/B0FFHMBFNZ"
        v-if="!isMobile"
        style="margin-left: 50px"
        >导航</a
      >
      <div v-else @click="showPop">点击导航</div>
    </div>
    <van-popup
      v-model="show"
      closeable
      position="bottom"
      :style="{ height: '30%' }"
    >
      <div class="menus">
        <a
          vlink="#000"
          class="menu"
          :href="item.src"
          v-for="(item, index) in menu"
          :key="index"
          >{{ item.name }}</a
        >
      </div>
    </van-popup>
  </div>
</template>
<script>
export default {
  data() {
    return {
      isMobile: false, //  为false 是PC 为true是手机
      show: false,
      menu: [
        { name: "高德地图", src: "https://www.amap.com/place/B0FFHMBFNZ" },
        {
          name: "腾讯地图",
          src: "https://router.map.qq.com/short?l=e149b584118af12b0d58c1a750272566",
        },
        { name: "百度地图", src: "https://j.map.baidu.com/a9/cCQu" },
      ],
    };
  },
  created() {},
  mounted() {
    this.isMobile = this._isMobile();
  },
  methods: {
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag != null;
    },
    showPop() {
      console.log("123");
      this.show = true;
    },
  },
};
</script>
<style lang="less" scoped>
@media (min-width: 768px) {
  .container {
    width: 75vw;
    margin: 20px auto 0 auto;
    box-shadow: 0 0 3px rgb(0 0 0 / 30%);
    height: 50vh;
    text-align: center;
    .title {
      margin-top: 20px;
      font-size: 30px;
      line-height: 100px;
      height: 100px;
      color: #777;
      text-shadow: 0 0 1px rgb(0 0 0 / 5%), 0 1px 2px rgb(0 0 0 / 30%);
      margin-bottom: 30px;
      border-bottom: 1px dashed #eee;
    }
    .tel {
      display: flex;
      align-items: center;
      margin: 0 100px;
      font-size: 20px;
    }
    .position {
      align-items: center;
      padding-top: 30px;
      display: flex;
      margin: 0 100px;
      font-size: 20px;
    }
  }
}
@media (max-width: 768px) {
  a:visited {
    color: #000;
  }
  /* 未访问链接*/
  a:link {
    color: #000;
  }
  .container {
    width: 75vw;
    margin: 20px auto 0 auto;
    box-shadow: 0 0 3px rgb(0 0 0 / 30%);
    height: 50vh;
    text-align: center;
    .title {
      margin-top: 20px;
      font-size: 30px;
      line-height: 100px;
      height: 100px;
      color: #777;
      text-shadow: 0 0 1px rgb(0 0 0 / 5%), 0 1px 2px rgb(0 0 0 / 30%);
      margin-bottom: 30px;
      border-bottom: 1px dashed #eee;
    }
    .tel {
      align-items: center;
      margin: 0 auto;
      width: 100%;
      font-size: 20px;
    }
    .position {
      align-items: center;

      padding-top: 30px;
      display: flex;
      margin: 0 auto;
      justify-content: center;
      font-size: 20px;
    }
    .menus {
      margin-top: 55px;
      .menu {
        display: block;
        font-size: 20px;
        padding: 10px 0;
      }
    }
  }
}
</style>
