import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Introduce from "../views/churchIntroduce.vue";
import HomeDetail from "../views/homeDetail.vue";
import Contact from "../views/contact.vue";
import PartTime from "../views/partMessage.vue";
import MessageDetail from "../views/messageDetail.vue";
import Activity from "../views/churchActivity.vue";
import FaithEquipment from "../views/faithEquipment.vue";
import ReligionPermission from "../views/religionPermission.vue";
import SearchResult from "../views/SearchResult.vue";
import MorningLearning from "../views/MorningLearning.vue";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/home/homeDetail",
  },
  {
    path: "/home/homeDetail",
    name: "Home",
    component: Home,
    redirect: "/home/homeDetail",
    children: [
      {
        path: "/home/homeDetail", // 绝对路径
        component: HomeDetail,
      },
      {
        path: "/home/introduce", // 教堂简介
        component: Introduce,
      },
      {
        path: "/home/partTime", // 聚会信息
        component: PartTime,
      },
      {
        path: "/home/contact", // 绝对路径
        component: Contact,
      },
      {
        path: "/home/messageDetail", // 绝对路径
        component: MessageDetail,
      },
      {
        path: "/home/activity", // 绝对路径
        component: Activity,
      },
      {
        path: "/home/faithEquipment",
        component: FaithEquipment,
      },
      {
        path: "/home/religionPermission",
        component: ReligionPermission,
      },
      {
        path: "/home/resultSearch",
        component: SearchResult,
      },
      {
        path: "/home/morningLearning",
        component: MorningLearning,
      },
    ],
  },
];

const router = new VueRouter({
  routes,
});
router.afterEach(() => {
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 0);
});

export default router;
