<template>
  <div class="container">
    <div class="title">
      {{currentItem.title}}
      <!-- <div class="desc">
        <div class="publishTime">发布时间:{{ publishTime }}</div>
        <div class="browserCount">浏览次数:{{ browserCount }}</div>
      </div> -->
    </div>
    <div class="main">
      <div class="body previewContent" v-html="currentItem.body"></div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      currentId:"",
      publishTime: 0 ,
      browserCount:  0,
      currentItem:{}
    };
  },
  created() {
 
  },
  mounted(){
    this.currentId = this.$route.query.id
    this.getDetailMessage();
  },
  methods: {
    async getDetailMessage(){
      const {data:{data:res}} =   await  this.$http.get(`/api/page/${this.currentId}`)
      this.currentItem =  res    
      this.$nextTick(() => {
        const video = this.$el.querySelector('video');
        console.log("video",video)
        video.style.width = '50vw';
        video.addEventListener('play', () => {
          video.style.width = '75vw';
       
      });
      video.addEventListener('pause', () => {
        
      });
      }) 
    }
  },
};
</script>
<style lang="less" scoped>
.previewContent {
  overflow: hidden;
  max-width: 100%;
  max-height: 100%;
}
/deep/ .previewContent img {
  object-fit: contain;
  max-width: 100%;
  max-height: 100%;
}
@media (min-width: 768px) {
  .container {
    width: 75vw;
    margin: 20px auto 0 auto;
    box-shadow: 0 0 3px rgb(0 0 0 / 30%);
    height: auto;
    min-height: 50vh;
    text-align: center;
    .title {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 20px;
      font-size: 30px;
      height: 100px;
      color: #777;
      text-shadow: 0 0 1px rgb(0 0 0 / 5%), 0 1px 2px rgb(0 0 0 / 30%);
      margin-bottom: 30px;
      border-bottom: 1px dashed #eee;
      .desc {
        display: flex;
        color: #999999;
        text-shadow: none;
        margin-top: 10px;
        font-size: 14px;
        .publishTime {
          margin-right: 20px;
        }
      }
    }
  }
}
@media (max-width: 768px) {
  .container {
    width: 75vw;
    margin: 20px auto 0 auto;
    box-shadow: 0 0 3px rgb(0 0 0 / 30%);
    height: auto;
    min-height: 50vh;
    text-align: center;
    .title {
      margin-top: 20px;
      height: 100px;
      font-size: 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: #777;
      text-shadow: 0 0 1px rgb(0 0 0 / 5%), 0 1px 2px rgb(0 0 0 / 30%);
      margin-bottom: 30px;
      border-bottom: 1px dashed #eee;
      .desc {
        display: flex;
        color: #999999;
        text-shadow: none;
        margin-top: 10px;
        font-size: 14px;
        .publishTime {
          margin-right: 20px;
        }
      }
    }
  }
}
.main {
  padding: 0 10px;
  .body {
    video {
      width: 55vw !important;
    }
    img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
