<template>
  <div class="container">
    <div class="title">灵修动态</div>
    <div class="list">
      <div class="item" v-for="(item, index) in list" :key="index" @click="toDetail(item.id)">
        <div class="itemType">『{{ item.type }}』</div>
        <div class="itemMessage">【{{ item.createTime }}】{{ item.title }}</div>
      </div>
    </div>
    <el-pagination style="height: 50px; margin-top: 50px" background layout="prev, pager, next" :total="list.length">
    </el-pagination>
  </div>
</template>
<script>
export default {
  data() {
    return {
      list: [
      ],
    };
  },
  created() {},
  mounted(){
    this.getFaithEquipment()
  },
  methods: {
    toDetail(id) {
      this.$router.push({
        path: "/home/messageDetail",
        query:{id}
      });
    },
    async getFaithEquipment(){
      const{data:{ data:res } }   = await this.$http.get("/api/page/equip")
      this.list = res.records
    }
  },
};
</script>
<style lang="less" scoped>
@media (min-width: 768px) {
  .container {
    width: 75vw;
    margin: 20px auto 0 auto;
    box-shadow: 0 0 3px rgb(0 0 0 / 30%);
    text-align: center;
    .title {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 20px;
      font-size: 30px;
      line-height: 100px;
      height: 100px;
      color: #777;
      text-shadow: 0 0 1px rgb(0 0 0 / 5%), 0 1px 2px rgb(0 0 0 / 30%);
      margin-bottom: 30px;
      border-bottom: 1px dashed #eee;
    }
    .list {
      // margin-left: 60px;
      font-size: 26px;
      padding: 0 60px;
      .item {
        display: flex;
        align-items: center;
        padding: 0 20px;
        height: 60px;
        border-bottom: 1px dashed #e3e3e3;
        cursor: pointer;
        overflow: hidden;
        white-space: nowrap;
        .itemMessage {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        .item .itemType:hover {
          text-decoration: underline;
          color: #a80000;
        }
        .itemMessage:hover {
          text-decoration: underline;
          color: #a80000;
        }
      }
    }
  }
}
@media (max-width: 768px) {
  .container {
    width: 90vw;
    margin: 20px auto 0 auto;
    box-shadow: 0 0 3px rgb(0 0 0 / 30%);
    text-align: center;

    .title {
      width: 100%;
      margin-top: 20px;
      font-size: 30px;
      line-height: 100px;
      height: 100px;
      color: #777;
      text-shadow: 0 0 1px rgb(0 0 0 / 5%), 0 1px 2px rgb(0 0 0 / 30%);
      margin-bottom: 30px;
      border-bottom: 1px dashed #eee;
    }
    .list {
      // margin-left: 60px;
      font-size: 14px;
      padding: 0 10px;
      width: 100%;

      .item {
        display: flex;
        align-items: center;
        padding: 0 15px 0 5px;

        height: 60px;
        border-bottom: 1px dashed #e3e3e3;
        cursor: pointer;
        .itemType:hover {
          text-decoration: underline;
          color: #a80000;
        }
        .itemType {
          width: 150px;
        }
        .itemMessage {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        .itemMessage:hover {
          text-decoration: underline;
          color: #a80000;
        }
      }
    }
  }
}
</style>
