<template>
  <div class="list">
    <div class="item" v-for="(item, index) in content" :key="index">
      <span class="row" @click="ToDetail(item.id,item.type)">[{{ item.type }}] {{ item.title }}</span>
    </div>
  </div>
</template>
<script>
export default {
  // type, title
  props: ["content"],
  data() {
    return {};
  },
  created() {},
  methods: {
    ToDetail(id) {
      this.$router.push({
        path: "/home/messageDetail",
        query:{id}
      });
    },
  },
};
</script>
<style lang="less" scoped>
.list {
  padding: 15px;
  background-color: #f4f4f4;
  .item {
    .row {
      display: block;
      width: 100%;
      height: 29px;
      /*第一步： 溢出隐藏 */
      overflow: hidden;
      /* 第二步：让文本不会换行， 在同一行继续 */
      white-space: nowrap;
      /* 第三步：用省略号来代表未显示完的文本 */
      text-overflow: ellipsis;
      line-height: 29px;
      // padding-bottom: 1px;
      border-bottom: 2px dotted #e3e3e3;
      cursor: pointer;
    }
    .row:hover {
      text-decoration: underline;
      color: #d08100;
    }
  }
}
</style>
