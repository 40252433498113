import axios from "axios";
export default {
  install: function (vue) {
    var service = axios.create({
      baseURL: "",
    });
    service.interceptors.request.use(
      (req) => {
        if (req.url == "/api/admin/user/login") return req;
        if (req.headers) {
          console.log("req", req);
          // BUG点在于当localstorage.getIntem 找到内容为空的时候会直接不发送请求 也不报错
          console.log(JSON.parse(localStorage.getItem("userInfo"))?.token);
          req.headers.token =
            JSON.parse(localStorage.getItem("userInfo"))?.token || "";
        }
        return req;
      },
      (err) => {}
    );
    service.interceptors.response.use(
      (req) => {
        if (req.data.errno == 0) {
          console.log("success", req.data.data);
          if (typeof req.data.data == "string") {
            // message.success(req.data.data);
          }
        } else if (req.data.errno == 1) {
          console.log("error");
          if (typeof req.data.data == "string") {
            // message.error(req.data.data);
          }
        }
        return req;
      },
      (err) => {}
    );
    const request = {};
    request.get = function (url, data, option) {
      return service.get(url, {
        //data数据要给到axios Get请求的 params
        params: data,
        ...option,
      });
    };
    request.post = function (url, data, option) {
      return service.post(url, data, option);
    };
    request.put = function (url, data, option) {
      return service.put(url, data, option);
    };
    request.delete = function (url, data, option) {
      return service.delete(url, {
        data,
        ...option,
      });
    };
    vue.prototype.$http = request;
  },
};
