<template>
  <div class="home_container">
    <!-- 顶部 -->
    <div class="top">
      <div class="time">{{dateTime}} 欢迎光临温州状元教会</div>
      <div class="contact">
        <svg class="icon" aria-hidden="true">
          <use xlink:href="#icon-dianhua1"></use>
        </svg>
        13587635033
        <svg class="icon" aria-hidden="true" style="font-size: 20px; cursor: pointer">
          <use xlink:href="#icon-xinlang"></use>
        </svg>

        <svg class="icon" aria-hidden="true" style="font-size: 20px; margin-left: 5px; cursor: pointer">
          <use xlink:href="#icon-weixin-copy"></use>
        </svg>
      </div>
    </div>
    <!-- 导航栏 -->
    <div class="navMenus">
      <router-link class="navMenu" :to="{ path: '/home/homeDetail' }" style="">首页</router-link>
      <router-link class="navMenu" :to="{ path: '/home/introduce' }">关于教会</router-link>
      <router-link class="navMenu" :to="{ path: '/home/activity' }">教会动态</router-link>
      <router-link class="navMenu" :to="{ path: '/home/faithEquipment' }">灵修动态</router-link>
      <router-link class="navMenu" :to="{ path: '/home/partTime' }">
        聚会信息
      </router-link>
      <router-link class="navMenu" :to="{ path: '/home/contact' }">联系我们</router-link>
    </div>
    <router-view />
    <div class="footerPic">
      <div class="footerPicTitle">
        <span>你们是世上的光。城造在山上,是不能隐藏的。</span>
        <span style="text-align: right">——太5:14</span>
      </div>
      <img src="../assets/image/footer.jpg" style="width: 100%; height: 100%" />
    </div>
    <div class="footer">
      <div class="headTitle">
        温州状元基督教堂 | 地址:温州市龙湾区状元街道前潘路16号 | 电话:
        13587635033
      </div>
      <div class="content">
        申明: 未经本站允许,不得转载本站文字、音频、图片等信息 |
        <a href="https://beian.miit.gov.cn/" target="_blank" class="linkHoverStyle" style="color: #337ab7; transition: 0.25s ease">浙ICP备2023012514号-1</a>
        |
        <a href="#" class="linkHoverStyle" @click="RouterIntoReligionPermission" style="cursor: pointer; color: #337ab7; transition: 0.25s ease">
          互联网宗教信息服务许可证:浙 (2022) 0000675
        </a>
      </div>
      <div style="
          margin: 20px auto 0 auto;
          padding: 20px 0;
          display: flex;
          font-size: 14px;
        ">
        <a target="_blank" href="" style="
            display: inline-block;
            text-decoration: none;
            height: 20px;
            line-height: 20px;
          "><img src="../assets/image/policeLogo.png" style="float: left" />
          <p style="
              float: left;
              height: 20px;
              line-height: 20px;
              margin: 0px 0px 0px 5px;
              color: #939393;
            ">
            浙公网安备 33030302001264号
          </p>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import CartPart from "../components/CardPart.vue";
export default {
  data() {
    return {
      dateTime:"",
    };
  },
  updated() {},
  components: {
    CartPart,
  },
  created() {this.init()},
  methods: {
    init(){
      let date = new Date();
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = date.getDate();
      this.dateTime = year +"年"+month+"月"+day+"日"
    },
    RouterIntoReligionPermission() {
      console.log("跳转");
      document.body.scrollTop = 0;
      this.$router.push("/home/religionPermission");
    },
  },
};
</script>
<style scoped lang="less">
.linkHoverStyle:hover {
  text-decoration: underline !important;
  color: #235271 !important;
}
@media (max-width: 768px) {
  .home_container {
    overflow-x: hidden;
    // overflow-y: scroll;
    .top {
      width: 100vw;
      margin: 0 auto;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      color: #777;
      font-size: 14px;
      padding: 9px 0;
      .contact {
        font-size: 14px;
      }
    }
    .header {
      position: relative;
      width: 100vw;
      background-color: pink;
      height: 150px; //具体根据图片来确定高度
      .backgroundPic {
        width: 100%;
        height: 100%;
      }
      .logoPic {
        position: absolute;
        height: 100%;
        top: 0;
        z-index: 99;
      }
      .QRCode {
        position: absolute;
        height: 100%;
        top: 0;
        right: 10vw;
        z-index: 99;
      }
    }
    .navMenus {
      display: flex;
      overflow: auto;
      width: 100vw;
      flex-wrap: wrap;
      background-image: linear-gradient(to right, #49b1e4 0, #5d5ba9 100%);
      // background-repeat: repeat-x;
      justify-content: center;
      align-items: center;

      .navMenu {
        // width: 140px;
        width: 33.33vw;
        height: 40px;
        line-height: 40px;
        text-align: center;
        color: #fff;
        position: relative;
        cursor: pointer;
        .subNavMenus {
          z-index: 999;
          display: none;
          position: absolute;
          width: 33.33vw;
          height: 40px;
          cursor: pointer;
          color: #000;
          .subNavMenu {
            margin-top: 1px;
            background-color: #f7dc53;
          }
        }
      }
      .navMenu:hover {
        color: #000;
        background-color: #f7dc53;
        cursor: pointer;
      }
      .navMenu:hover .subNavMenus {
        display: block;
      }
    }
    .footerPic {
      margin-top: 50px;

      width: 100vw;
      // height: 250px;
      height: 300px;
      position: relative;

      background-color: pink;
      .footerPicTitle {
        width: 38vw;
        display: flex;
        flex-direction: column;
        position: absolute;
        font-size: 1.4rem;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
    .footer {
      padding: 0 30px;
      box-sizing: border-box;
      width: 100%;
      height: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      background-color: #363636;
      color: #fff;
      padding: 0 50px;
      font-size: 18px;
      .headTitle {
        font-size: 16px !important;
        margin: 20px 0 40px 0;
      }
    }
  }
}
@media (min-width: 768px) and (max-width: 992px) {
  .home_container {
    overflow-x: hidden;
    .top {
      width: 750px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      color: #777;
      font-size: 14px;
      padding: 9px 0;
    }
    // 顶部图片
    .header {
      position: relative;
      width: 100vw;
      background-color: pink;
      height: 150px; //具体根据图片来确定高度
      .backgroundPic {
        width: 100%;
        height: 100%;
      }
      .logoPic {
        position: absolute;
        height: 100%;
        top: 0;
        z-index: 99;
      }
      .QRCode {
        position: absolute;
        height: 100%;
        top: 0;
        right: 5vw;
        z-index: 99;
      }
    }
    .navMenus {
      display: flex;
      height: 40px;
      background-image: linear-gradient(to right, #49b1e4 0, #5d5ba9 100%);
      background-repeat: repeat-x;
      justify-content: center;
      align-items: center;
      .navMenu {
        width: 140px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        color: #fff;
        position: relative;
        cursor: pointer;
        .subNavMenus {
          z-index: 999;
          display: none;
          position: absolute;
          width: 140px;
          height: 40px;
          cursor: pointer;
          color: #000;
          .subNavMenu {
            margin-top: 1px;
            background-color: #f7dc53;
          }
        }
      }
      .navMenu:hover {
        color: #000;
        background-color: #f7dc53;
        cursor: pointer;
      }
      .navMenu:hover .subNavMenus {
        display: block;
      }
    }
    .footerPic {
      margin-top: 50px;
      width: 100%;
      height: 250px;
      background-color: pink;
      position: relative;
      .footerPicTitle {
        width: 38vw;
        display: flex;
        flex-direction: column;
        position: absolute;
        font-size: 26px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
    .footer {
      width: 100%;
      height: 250px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      background-color: #363636;
      color: #fff;
      font-size: 22px;
      .headTitle {
        margin: 20px 0 40px 0;
      }
    }
  }
}
@media (min-width: 992px) and (max-width: 1200px) {
  .home_container {
    overflow-x: hidden;
    .top {
      width: 970px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      color: #777;
      font-size: 14px;
      padding: 9px 0;
    }
    .header {
      width: 100vw;
      position: relative;
      background-color: pink;
      height: 150px; //具体根据图片来确定高度

      .logoPic {
        position: absolute;
        height: 100%;
        top: 0;
        z-index: 99;
      }
      .QRCode {
        position: absolute;
        height: 100%;
        top: 0;
        right: 5vw;
        z-index: 99;
      }
      .backgroundPic {
        width: 100%;
        height: 100%;
      }
    }
    .navMenus {
      display: flex;
      height: 40px;
      background-image: linear-gradient(to right, #49b1e4 0, #5d5ba9 100%);
      background-repeat: repeat-x;
      justify-content: center;
      align-items: center;
      .navMenu {
        width: 115px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        color: #fff;
        position: relative;
        cursor: pointer;
        .subNavMenus {
          z-index: 999;
          display: none;
          position: absolute;
          width: 115px;
          height: 40px;
          cursor: pointer;
          color: #000;
          .subNavMenu {
            margin-top: 1px;
            background-color: #f7dc53;
          }
        }
      }
      .navMenu:hover {
        color: #000;
        background-color: #f7dc53;
        cursor: pointer;
      }
      .navMenu:hover .subNavMenus {
        display: block;
      }
    }
    .footerPic {
      margin-top: 50px;
      width: 100%;
      height: 250px;
      background-color: pink;
      position: relative;

      .footerPicTitle {
        width: 38vw;
        display: flex;
        flex-direction: column;
        position: absolute;
        font-size: 26px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
    .footer {
      width: 100%;
      height: 250px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      background-color: #363636;
      color: #fff;
      font-size: 22px;
      .headTitle {
        margin: 20px 0 40px 0;
      }
    }
  }
}
// 当最小宽度在>768时候 以下样式会触发
@media (min-width: 1200px) {
  .home_container {
    overflow-x: hidden;
    // 顶部 联系方式等
    .top {
      width: 1170px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      color: #777;
      font-size: 14px;
      padding: 9px 0;
      .contact {
        font-size: 14px;
      }
    }
    // 顶部图片
    .header {
      // width: 100vw;
      background-color: pink;
      height: 150px; //具体根据图片来确定高度
      width: 100vw;
      position: relative;
      .backgroundPic {
        width: 100%;
        height: 100%;
      }
      .logoPic {
        position: absolute;
        height: 100%;
        top: 0;
        z-index: 99;
      }
      .QRCode {
        position: absolute;
        height: 100%;
        top: 0;
        right: 5vw;
        z-index: 99;
      }
    }
    .navMenus {
      display: flex;
      height: 40px;
      background-image: linear-gradient(to right, #49b1e4 0, #5d5ba9 100%);
      background-repeat: repeat-x;
      justify-content: center;
      align-items: center;

      .navMenu {
        width: 140px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        color: #fff;
        position: relative;
        cursor: pointer;
        .subNavMenus {
          z-index: 999;
          display: none;
          position: absolute;
          width: 140px;
          height: 40px;
          cursor: pointer;
          color: #000;
          .subNavMenu {
            margin-top: 1px;
            background-color: #f7dc53;
          }
        }
      }
      .navMenu:hover {
        color: #000;
        background-color: #f7dc53;
        cursor: pointer;
      }
      .navMenu:hover .subNavMenus {
        display: block;
      }
    }
    .footerPic {
      margin-top: 50px;
      width: 100%;
      height: 250px;
      position: relative;
      background-color: pink;
      .footerPicTitle {
        width: 38vw;
        display: flex;
        flex-direction: column;
        position: absolute;
        font-size: 26px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
    .footer {
      display: flex;
      flex-direction: column;
      // justify-content: center;
      flex-wrap: wrap;
      align-items: center;
      width: 100%;
      height: 250px;
      background-color: #363636;
      color: #fff;
      font-size: 24px;
      .headTitle {
        margin: 60px 0 40px 0;
      }
    }
  }
}
// 公共样式 不放进媒体查询
.el-carousel__item h3 {
  color: #475669;
  font-size: 18px;
  opacity: 0.75;
  line-height: 300px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
.navMenu {
  text-decoration: none;
}
.content {
  font-size: 1.1rem;
}
</style>
