<template>
  <div class="cardContainer" style="width: 100%">
    <div class="imgTitle">
      <img :src="CurrentMessage.pic" alt="" style="width: 100%; height: 100%" />
      <div class="absoluteTitle">
        <span style="padding-left: 10px">{{ CurrentMessage.title }}</span>
        <span style="padding-right: 10px" class="MoreShowUnderline" @click="RouterToMore(CurrentMessage.title)">++更多</span>
      </div>
    </div>
    <div class="messageList">
      <MessageList :content="CurrentMessage.MessageList" />
    </div>
  </div>
</template>
<script>
import MessageList from "./MessageList.vue";
export default {
  props: ["CurrentMessage"],
  name: "CartPart",
  data() {
    return {};
  },
  components: {
    MessageList,
  },
  created() {},
  methods: {
    RouterToMore(title) {
      console.log("CurrentMessage",this.CurrentMessage.parentId)
       if(this.CurrentMessage.parentId ==1){ //1是教会动态
          this.$router.push({
            path: "/home/activity",
          });
       }else if(this.CurrentMessage.parentId ==2){
        this.$router.push({
            path: "/home/faithEquipment",
          });
       }else{
        this.$router.push({
            path: "/home/morningLearning",
          });
       }
   
    },
  },
};
</script>
<style scoped lang="less">
.cardContainer {
  margin-top: 15px;
  width: 100%;
  height: 100%;

  .imgTitle {
    position: relative;
    width: 374px;
    height: 140px;

    .absoluteTitle {
      width: 100%;
      height: 35px;
      background-color: rgba(101, 170, 234, 0.6);
      position: absolute;
      top: 105px;
      line-height: 35px;
      display: flex;
      justify-content: space-between;
      color: #fff;

      .MoreShowUnderline:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }

  .messageList {
    width: 374px;
  }
}

// 下面如果匹配到 就覆盖前面的样式
@media (min-width: 992px) and (max-width: 1200px) {
  .cardContainer {
    .imgTitle {
      position: relative;
      width: 303px;
      height: 115px;

      .absoluteTitle {
        top: 80px;
      }

      img {
        width: 100%;
        height: 100%;
      }
    }

    .messageList {
      width: 303px;
    }
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .cardContainer {
    .imgTitle {
      position: relative;
      width: 229px;
      height: 86px;

      .absoluteTitle {
        top: 50px;
      }

      img {
        width: 100%;
        height: 100%;
      }
    }

    .messageList {
      width: 229px;
    }
  }
}

@media (max-width: 768px) {
  .cardContainer {
    .imgTitle {
      position: relative;
      width: 100%;
      height: 140px;

      .absoluteTitle {
        top: 105px;
      }

      img {
        width: 100%;
        height: 100%;
      }
    }

    .messageList {
      width: 100%;
    }
  }
}
</style>
